import { Helmet } from 'react-helmet';

const generateMetatags = ({
  // Required params
  url = '/',
  title = 'Blog Codeeweb',
  description = '',
  color = '#2c4bff',

  // Optional params
  hasCanonicalTag,
  canonical,
  shareTitle,
  shareDescription,
  shareImage = 'https://codeeweb.com.br/assets/images/about-6.jpg',
}) => {
  return (
    <>
      {/* Default Metatags */}
      <Helmet>
        <title>{title}</title>
        <meta name='theme-color' content={color} />
        <meta name='description' content={description} />
        {hasCanonicalTag ? (
          <link rel='canonical' href={canonical ? canonical : url} />
        ) : (
          ''
        )}

        {/* Open Graph meta-tags */}
        <meta property='og:type' content='website' />
        <meta property='og:image' content={shareImage} />
        <meta property='og:site_name' content='Itaú' />
        <meta property='og:url' content={url} />
        <meta property='og:title' content={shareTitle || title} />
        <meta
          property='og:description'
          content={shareDescription || description}
        />
        {/* Twitter meta-tags */}
        <meta name='twitter:image' content={shareImage} />
        <meta name='twitter:url' content={url} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content={shareTitle || title} />
        <meta
          name='twitter:description'
          content={shareDescription || description}
        />
      </Helmet>
    </>
  );
};

export default generateMetatags;
