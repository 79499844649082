const Footer = () => {
  return (
    <>
      <div className='container position-relative z-index-20'>
        <div className='my-10 bg-primary p-4 p-md-6 rounded text-white d-flex align-items-center position-relative'>
          <div className='row'>
            <div className='col-12 col-md-5 position-relative z-index-20'>
              <h4 className='fs-3 fw-bold mb-3'>
                Esta procurando desenvolvimento web?
              </h4>
              <p className='opacity-75'>
                Nossa empresa é focada em arquitetar e simplificar o nascimento
                do seu website ou produto digital.
              </p>
              <a
                href='https://codeeweb.com.br/contato.html'
                className='btn btn-white'
                role='button'
              >
                Vamos conversar
              </a>
            </div>
            <div className='col-12 col-md-6 offset-md-1 mt-5 mt-lg-0'>
              <ul className='list-unstyled'>
                <li className='me-4 mb-2 d-flex align-items-center'>
                  <i className='ri-checkbox-circle-fill opacity-75 ri-lg me-1'></i>{' '}
                  Pensamos na arquitetura
                </li>
                <li className='me-4 mb-2 d-flex align-items-center'>
                  <i className='ri-checkbox-circle-fill opacity-75 ri-lg me-1'></i>{' '}
                  Escalabilidade
                </li>
                <li className='me-4 mb-2 d-flex align-items-center'>
                  <i className='ri-checkbox-circle-fill opacity-75 ri-lg me-1'></i>{' '}
                  Manutenção flexível
                </li>
              </ul>
            </div>
          </div>

          <div className='position-absolute top-0 end-0 start-0 bottom-0 z-index-0 d-none d-lg-block overflow-hidden'>
            <div className='d-block f-w-80  position-absolute top-10 end-n25 opacity-25'>
              <span className='d-block'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 55.95 50.74'
                >
                  <path
                    className='text-white'
                    d='M55.45,34.33A15.92,15.92,0,1,1,39.54,18.41,15.91,15.91,0,0,1,55.45,34.33Z'
                    fill='none'
                    stroke='currentColor'
                    strokeMiterlimit='10'
                  ></path>
                  <path
                    className='text-white'
                    d='M34.33,18.41A15.92,15.92,0,1,1,18.41,2.5,15.92,15.92,0,0,1,34.33,18.41Z'
                    fill='none'
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='5'
                  ></path>
                </svg>{' '}
              </span>
            </div>
          </div>
        </div>
      </div>
      <footer className='bg-dark pt-10 pb-8  '>
        <div className='container'>
          <div className='border-bottom border-white-10 pb-7'>
            <div className='col-12 col-md-8 col-lg-6 d-flex mx-auto flex-column align-items-center justify-content-center'>
              <h2 className='fs-1 fw-bolder text-white text-center'>
                Não perca tempo 🚀 Evolua seu negócio com nossas skills.
              </h2>
              <a
                href='https://codeeweb.com.br/contato.html'
                className='btn btn-primary mt-3 w-100 w-md-auto'
                role='button'
              >
                Fale conosco
              </a>
            </div>
          </div>
        </div>

        <div className='container pt-7'>
          <div className='d-flex flex-column flex-md-row justify-content-md-between align-items-center'>
            <a
              href='https://codeeweb.com.br'
              className='d-flex align-items-center lh-1 text-white transition-opacity opacity-50-hover text-decoration-none mb-4 mb-md-0'
            >
              <span className='f-w-7 d-block text-success me-2'>
                <svg
                  className='w-100'
                  xmlns='http://www.w3.org/2000/svg'
                  viewBox='0 0 246 270'
                >
                  <path
                    data-uid='o_f4k06f0lh_7'
                    fill='rgba(93, 235, 223, 1)'
                    transform='matrix(3, 0, 0, 3, 0, 0)'
                    d='M50 10L15 30v40l35 20l34.99-19.994L85 69.99V30L50 10z M50 17.699L74.89 31.92l-6.732 3.848L50 25.397L31.846 35.771  l-6.738-3.851L50 17.699z M64.795 41.546v13.059L53.369 48.08V35.02L64.795 41.546z M46.631 35.02v13.057l-11.423 6.527V41.546  L46.631 35.02z M46.631 80.377L21.735 66.152v-28.46l6.734 3.848v20.761L46.631 72.68V80.377z M38.571 60.377l11.426-6.529  l11.429 6.529L50 66.904L38.571 60.377z M78.265 66.152L53.369 80.377V72.68L71.53 62.305V41.54l6.734-3.848V66.152z'
                    style={{
                      transform:
                        'translate(-39px, -30px) matrix(3.2, 0, 0, 3.2, 0, 0)',
                    }}
                  ></path>
                </svg>
              </span>

              <span className='fw-bold'>Codeeweb</span>
            </a>

            <ul className='list-unstyled d-flex align-items-center justify-content-end'>
              <li className='ms-5'>
                <a
                  href='https://www.instagram.com/codee_web/'
                  target='_blank'
                  className='text-white text-decoration-none opacity-50-hover transition-opacity'
                >
                  <i className='ri-instagram-line ri-lg'></i>
                </a>
              </li>
              <li className='ms-5'>
                <a
                  href='https://www.linkedin.com/company/codeeweb/'
                  target='_blank'
                  className='text-white text-decoration-none opacity-50-hover transition-opacity'
                >
                  <i className='ri-linkedin-fill ri-lg'></i>
                </a>
              </li>
            </ul>
          </div>

          <div className='d-flex flex-wrap justify-content-between mt-5 mt-lg-7'>
            <div className='w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0'>
              <h6 className='text-uppercase fs-xs fw-bolder tracking-wider text-white opacity-50'>
                Mapa do Site
              </h6>
              <ul className='list-unstyled footer-nav'>
                <li>
                  <a href='https://codeeweb.com.br/'>Home</a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/sobre-nos.html'>Sobre nós</a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/contato.html'>Contato</a>
                </li>
              </ul>
            </div>

            <div className='w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0'>
              <h6 className='text-uppercase fs-xs fw-bolder tracking-wider text-white opacity-50'>
                Produto ou website
              </h6>
              <ul className='list-unstyled footer-nav'>
                <li>
                  <a href='https://codeeweb.com.br/contato.html'>
                    Desenvolvimento Produto
                    <span className='badge bg-danger rounded ms-2 align-middle'>
                      new
                    </span>
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/contato.html'>
                    Desenvolvimento Site
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/contato.html'>
                    Desenvolvimento Landinpage
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/contato.html'>
                    Manutenção de código
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0'>
              <h6 className='text-uppercase fs-xs fw-bolder tracking-wider text-white opacity-50'>
                Informações Legais
              </h6>
              <ul className='list-unstyled footer-nav'>
                <li>
                  <a href='https://codeeweb.com.br/politica-de-privacidade.html'>
                    Política de Privacidade
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/politica-de-privacidade.html'>
                    Política de Segurança
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/politica-de-privacidade.html'>
                    Termos e Condições
                  </a>
                </li>
                <li>
                  <a href='https://codeeweb.com.br/politica-de-privacidade.html'>
                    Política de Cookies
                  </a>
                </li>
              </ul>
            </div>

            <div className='w-100 w-sm-50 w-lg-auto mb-4 mb-lg-0'>
              <h6 className='text-uppercase fs-xs fw-bolder tracking-wider text-white opacity-50'></h6>
              <ul className='list-unstyled footer-nav'>
                <li>
                  <a href='#'></a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='border-top pt-6 mt-7 border-white-10 d-flex flex-column flex-md-row justify-content-between align-items-center'>
            <span className='small text-white opacity-50 mb-2 mb-md-0'>
              Todos os direitos reservados © Codeeweb 2023
            </span>
            <span className='small text-white opacity-50'>
              Termos e Serviços | Política de Seguraça
            </span>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
