const Header = () => {
  return (
    <nav className='navbar navbar-expand-lg navbar-light bg-white border-bottom'>
      <div className='container'>
        <a
          className='navbar-brand d-flex align-items-center lh-1 me-10 transition-opacity opacity-75-hover'
          href='/'
        >
          <span className='f-w-7 d-block text-success me-2'>
            <svg
              className='w-100'
              xmlns='http://www.w3.org/2000/svg'
              viewBox='0 0 246 270'
            >
              <path
                datauid='o_f4k06f0lh_7'
                fill='rgba(93, 235, 223, 1)'
                transform='matrix(3, 0, 0, 3, 0, 0)'
                d='M50 10L15 30v40l35 20l34.99-19.994L85 69.99V30L50 10z M50 17.699L74.89 31.92l-6.732 3.848L50 25.397L31.846 35.771  l-6.738-3.851L50 17.699z M64.795 41.546v13.059L53.369 48.08V35.02L64.795 41.546z M46.631 35.02v13.057l-11.423 6.527V41.546  L46.631 35.02z M46.631 80.377L21.735 66.152v-28.46l6.734 3.848v20.761L46.631 72.68V80.377z M38.571 60.377l11.426-6.529  l11.429 6.529L50 66.904L38.571 60.377z M78.265 66.152L53.369 80.377V72.68L71.53 62.305V41.54l6.734-3.848V66.152z'
                style={{
                  transform:
                    'translate(-39px, -30px) matrix(3.2, 0, 0, 3.2, 0, 0)',
                }}
              ></path>
            </svg>
          </span>

          <span className='fw-bold text-body'>
            Codeeweb <span className='text-primary'>{`<BLOG />`}</span>
          </span>
        </a>

        <div
          className='collapse navbar-collapse justify-content-between align-items-center'
          id='navbarSupportedContent'
        >
          <ul className='navbar-nav'>
            {/* <li className='nav-item'>
              <a className='nav-link' href='./sobre-nos.html'>
                Sobre-nós
              </a>
            </li> */}
          </ul>{' '}
          <div className='d-none d-lg-flex'>
            <a
              href='https://codeeweb.com.br/contato.html'
              target='_blank'
              className='btn btn-primary ms-2'
              role='button'
            >
              Contate-nos
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
